@tailwind base;
@tailwind components;
@tailwind utilities;

html { margin-left: calc(100vw - 100%); }

body {
  @apply bg-gray-900 text-white font-sans tracking-tight;
}

.tw-container {
  @apply max-w-screen-lg mx-auto px-10;
}

.form-search {
  @apply focus:outline-none bg-gray-900 bg-opacity-80 px-2 py-1 text-lg placeholder-gray-300 text-gray-200 shadow rounded-lg;
}

.form-login {
  @apply focus:outline-none bg-gray-900 bg-opacity-80 py-2 px-4 text-lg placeholder-gray-300 text-gray-200 shadow rounded-lg;
}

.form-general {
  @apply focus:outline-none bg-gray-800 bg-opacity-80 py-2 px-4 text-lg placeholder-gray-300 text-gray-200 shadow rounded-lg;
}

.form-default {
  @apply focus:outline-none bg-gray-800 bg-opacity-80 px-6 py-2 rounded-full text-base placeholder-gray-300 text-gray-200 shadow rounded-lg;
}

// -------------------- Buttons
.btn {
  @apply px-4 py-2 text-base uppercase text-white font-semibold transition ease-in-out duration-300 inline;
}

.btn-sm {
  @apply px-4 py-1.5 text-sm uppercase text-white font-semibold transition ease-in-out duration-300 inline;
}

.btn-lg {
  @apply px-6 py-2.5 text-base uppercase text-white font-semibold transition ease-in-out duration-300 inline;
}

.btn-default {
  @apply bg-gray-700 hover:opacity-70 focus:outline-none;
}

.btn-red {
  @apply bg-red-700 hover:opacity-70 focus:outline-none;
}

.btn-blue {
  @apply bg-blue-700 hover:opacity-70 focus:outline-none;
}

.btn-orange {
  @apply bg-orange-700 hover:opacity-70 focus:outline-none;
}

.btn-yellow {
  @apply bg-yellow-700 hover:opacity-70 focus:outline-none;
}

// --------------------- Headings
hr {
  @apply border-gray-700 my-5;
}
h1 {
  @apply text-2xl lg:text-3xl font-bold;
}

h2 {
  @apply text-xl lg:text-2xl font-semibold;
}

h3 {
  @apply text-lg lg:text-xl font-semibold;
}

h4 {
  @apply text-lg lg:text-xl;
}

// ----------------------- Scroll
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  @apply bg-gray-800 ;
}

/* Handle */
::-webkit-scrollbar-thumb {
  @apply bg-gray-300 rounded-full;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  @apply bg-gray-400;
}

/* Container */ 
.table-head { 
    @apply flex border-b-2 border-orange-400 text-base bg-gray-700 font-semibold py-2 px-4 rounded-t-lg;
}

.table-item {
    @apply flex border-b-2 border-gray-700 text-base bg-gray-700 bg-opacity-50 hover:bg-opacity-90 transition ease-in-out duration-300 py-2 px-4;
}

.table-item:last-child {
  @apply border-b-0 rounded-b-lg;
}

/* Panels */
.panel {
    @apply bg-gray-800 rounded-lg;
}

.panel .panel-head {
  @apply text-base font-semibold py-2.5 px-5 bg-gray-700 rounded-t-lg;
}

.panel .panel-body {
  @apply rounded-b-lg overflow-hidden;
}

.box-content {
  @apply py-3 px-6 bg-gray-800 mb-4 rounded-lg;
}

.box-stat {
  @apply bg-gray-800 rounded-2xl p-8;
}